import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export default function Hero() {
  const data = useStaticQuery(graphql`
    query HeroQuery {
      contentfulHero {
        image {
          gatsbyImageData
          title
        }
        heading
        subheading
      }
    }
  `);
  const image = getImage(data.contentfulHero.image);
  return (
    <section className="container p-4 bg-secondary">
      <h1 className="text-primary">{data.contentfulHero.heading}</h1>
      <h2 className="text-white">{data.contentfulHero.subheading}</h2>
      <GatsbyImage image={image} alt={image.title} />
    </section>
  );
}
